import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import toast from 'react-hot-toast'
// Components
import {
  AddCoupon,
  CartProductCard,
  Loading,
  OrderConfirmation,
  OrderDetails,
  OrderFailed,
  PageHeader,
  PaymentDetails,
  PayPalButton,
  PcAgreement,
  PcOffer,
  Primary as PrimaryButton,
  Seo,
  ShippingDetails,
} from '../components'

// Styles
import { Wrapper, Container, StyledSpan } from '../styles/common'
// Context
import { usePrismic } from '../context/PrismicContext'
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
// Hooks
import useSetShipping from '../hooks/useSetShipping'
// Utils
import { OUT_OF_STOCK } from '../utils/productHelpers'
import analytics from '../utils/analytics'
import TotalSavings from '../components/Cart/TotalSavings'

const initialCartState = {
  areTermsAccepted: false,
  orderError: null,
  orderNumber: null,
  orderStatus: null,
  receiveSmsEmail: false,
  showConfirmation: false,
  showOrderError: false,
}

const CartPage = () => {
  const [
    {
      areTermsAccepted,
      orderError,
      orderNumber,
      orderStatus,
      receiveSmsEmail,
      showConfirmation,
      showOrderError,
    },
    setCartState,
  ] = useState(initialCartState)

  const {
    prismicData: {
      prismicCartPage: {
        card_ending_in,
        continue_to_checkout,
        header,
        ignite_pickup,
        loading_cart,
        place_order,
        please_add_a_payment_method,
        please_add_shipping,
        processing_order,
        shipping_address_error,
        shipping_to,
        thanks_for_shopping_with,
        title,
        which_address_should_we_use,
      },
    },
  } = usePrismic()

  const {
    isAuthenticated,
    isEventSite,
    isVipSite,
    magentoUser,
    qUser,
    qUserUpline,
    userType,
    getSelectedCard,
    handleRefreshSite,
    updateQUser,
    manageReferral: { isEnrollmentComplete, isReferral, referralData },
  } = useAuthContext()

  const {
    buildCart,
    cartData,
    isAmbOfferInCart,
    isPcOfferInCart,
    isPlacingOrder,
    isShippingSet,
    manageCart: { placeOrder, doesCartHaveCBD },
    handleGetCartData,
    setIsPlacingOrder,
    upgradeUserAddPcFee,
  } = useCartContext()

  const { shippingAddress, shippingMethod } = useSetShipping(!!orderNumber)
  const { lastFourDigits, creditCardType } = getSelectedCard()

  const handleOrderError = (error, status) => {
    setCartState(prev => ({
      ...prev,
      orderError: error,
      orderStatus: status,
      showOrderError: true,
    }))
  }

  const handlePlaceOrder = async () => {
    if (isPcOfferInCart) {
      const data = {
        ...qUser,
        areTermsAccepted,
        receiveEmail: true,
        receiveText: true,
      }

      await updateQUser(data)
    }

    if (isAmbOfferInCart && !isEnrollmentComplete) {
      alert('Please complete your enrollment to purchase this offer.')
      navigate('/enrollment')
      return
    }

    if (!isShippingSet) {
      toast.error(shipping_address_error[0].text)
      return
    }
    if (!lastFourDigits) {
      toast.error(please_add_a_payment_method[0].text)
      return
    }

    window.scrollTo(0, 0) // scroll to top of page
    let { orderNumber, error, status } = await placeOrder(
      setIsPlacingOrder,
      cartData,
      getSelectedCard,
      buildCart,
      handleGetCartData,
      false,
      null
    )
    setCartState(prev => ({ ...prev, orderNumber }))
    if (error) handleOrderError(error, status)
    else {
      localStorage.setItem('showArqModal', isAmbOfferInCart && 'true')
      analytics('purchase')
      setCartState(prev => ({
        ...prev,
        showConfirmation: true,
      }))
    }
  }

  // paypal
  const [showPayPalButton, setShowPayPalButton] = useState(false)
  useEffect(() => {
    if (!cartData) return
    const hasCBD = doesCartHaveCBD(cartData)
    setShowPayPalButton(!hasCBD)
  }, [cartData])

  const handlePayPalClick = async () => {
    if (isPcOfferInCart) {
      const data = {
        ...qUser,
        areTermsAccepted,
        receiveEmail: true,
        receiveText: true,
      }

      await updateQUser(data).then(() => {
        // handleSetUserState({ isEnrollmentComplete: true })
      })
    }
  }

  const { currency = '', value: amount = 0 } =
    cartData?.prices?.grand_total ?? {}

  const createPayPalOrder = (_, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: currency,
              value: amount.toString(),
            },
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then(orderId => orderId)
  }

  const handlePayPalApproved = async (data, actions) => {
    const isPaypalMethod = true
    return actions.order.capture().then(async () => {
      window.scrollTo(0, 0) // scroll to top of page
      let { orderNumber, error, status } = await placeOrder(
        setIsPlacingOrder,
        cartData,
        getSelectedCard,
        buildCart,
        handleGetCartData,
        isPaypalMethod,
        data?.orderID
      )
      setCartState(prev => ({ ...prev, orderNumber }))
      if (error) handleOrderError(error, status)
      else {
        localStorage.setItem('showArqModal', isAmbOfferInCart && 'true')
        analytics('purchase')
        setCartState(prev => ({
          ...prev,
          showConfirmation: true,
        }))
      }
    })
  }

  const onPayPalError = error => {
    if (error) handleOrderError(error, 'PayPal failed to process order')
  }

  const isEventOrVip = isEventSite || isVipSite

  const shippingTo = () => {
    if (isEventOrVip) {
      return (
        <StyledSpan bold red>
          UNITE Event Pickup
        </StyledSpan>
      )
    }
    let shippingText = which_address_should_we_use[0].text
    if (!isShippingSet && !magentoUser?.addresses?.length)
      shippingText = please_add_shipping[0].text
    if (isShippingSet) {
      shippingText = `${shippingAddress?.street[0]}`
    }

    return (
      <Link to="/shipping-info">
        <StyledSpan bold>{shippingText}</StyledSpan>
      </Link>
    )
  }

  const cardInfo = () => {
    let cardText = lastFourDigits
      ? lastFourDigits
      : please_add_a_payment_method[0].text
    return (
      <Link to="/payment-info">
        <StyledSpan bold>{cardText}</StyledSpan>
      </Link>
    )
  }

  const handleAgreementChecked = (e, { name }) => {
    name === 'smsEmail'
      ? setCartState(prev => ({ ...prev, receiveSmsEmail: !receiveSmsEmail }))
      : setCartState(prev => ({ ...prev, areTermsAccepted: !areTermsAccepted }))
  }

  const isAProductOutOfStock = cartData?.items?.some(
    ({ product: { stock_status } }) => stock_status === OUT_OF_STOCK
  )

  const shoppingWith = () => {
    if (!isAuthenticated) {
      if (isReferral && referralData?.ambassadorName)
        return referralData?.ambassadorName
      return 'Q Sciences'
    }
    if (qUserUpline?.displayName === 'Par Partners LLC') return 'Q Sciences'

    return qUserUpline?.displayName
  }

  const shouldShowSavings =
    isPcOfferInCart ||
    (isAmbOfferInCart &&
      userType === 'RETAIL' &&
      cartData?.total_wholesale_savings) ||
    (userType !== 'RETAIL' && cartData?.total_savings)

  const shouldHidePcOffer =
    isPcOfferInCart ||
    isAmbOfferInCart ||
    userType !== 'RETAIL' ||
    isVipSite ||
    isEventSite

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL')

  const shouldShowShipping = !isEventOrVip

  if (!cartData)
    return <Loading loading={true} message={loading_cart[0].text} />

  if (isPlacingOrder)
    return (
      <Loading loading={isPlacingOrder} message={processing_order[0].text} />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        <>
          <PageHeader exitRoute="/">{header[0].text}</PageHeader>
          <Container padded>
            {userType === 'AMBASSADOR' ? (
              <StyledSpan data-qa="ambName" isTitle>
                {qUser?.displayName}
              </StyledSpan>
            ) : (
              <StyledSpan>
                {thanks_for_shopping_with[0].text}{' '}
                <StyledSpan data-qa="uplineName" isTitle>
                  {shoppingWith()}
                </StyledSpan>
              </StyledSpan>
            )}
            {isAuthenticated ? (
              <>
                <StyledSpan data-qa="shippingAddress">
                  {shipping_to[0].text} {shippingTo()}
                </StyledSpan>
                <StyledSpan data-qa="cardInfo">
                  {card_ending_in[0].text} {cardInfo()}
                </StyledSpan>
              </>
            ) : null}
            <OrderDetails
              {...cartData}
              shipping={shippingMethod}
              showShippingAndTax={isAuthenticated}
              showWholesaleTotal={isPcOfferInCart}
            />
            {shouldShowSavings ? (
              <TotalSavings
                savings={
                  isPcOfferInCart || isAmbOfferInCart
                    ? cartData.total_wholesale_savings
                    : cartData.total_savings
                }
                isPcMessage={isPcOfferInCart}
              />
            ) : null}
            {isAuthenticated && <AddCoupon />}
          </Container>
          <Container padded>
            {cartData?.items?.map(item => (
              <div key={item.uid}>
                <CartProductCard item={item} />
              </div>
            ))}
          </Container>
          {shouldHidePcOffer ? null : (
            <PcOffer addPcOfferToCart={upgradeUserAddPcFee} />
          )}
          {isAuthenticated ? (
            <>
              {shouldShowShipping ? (
                <ShippingDetails
                  address={shippingAddress}
                  showAddress={isShippingSet}
                />
              ) : null}
              <PaymentDetails
                ccType={creditCardType}
                lastFour={lastFourDigits}
              />
            </>
          ) : null}
          {isPcOfferInCart && isAuthenticated ? (
            <PcAgreement
              receiveSmsEmail={receiveSmsEmail}
              areTermsAccepted={areTermsAccepted}
              handleChecked={handleAgreementChecked}
            />
          ) : null}
          <Container style={{ marginTop: 'auto' }} align="center">
            {isAuthenticated ? (
              <>
                {showPayPalButton &&
                  cartData?.shipping_addresses?.length > 0 && (
                    <PayPalButton
                      onClick={handlePayPalClick}
                      createOrder={createPayPalOrder}
                      onApprove={handlePayPalApproved}
                      onError={onPayPalError}
                      currency={currency}
                      amount={amount.toString()}
                      disabled={
                        isAProductOutOfStock ||
                        (isAmbOfferInCart && !isEnrollmentComplete) ||
                        (isPcOfferInCart && !areTermsAccepted) ||
                        cartData?.items?.length === 0
                      }
                    />
                  )}
                <PrimaryButton
                  style={{ margin: '2em 0' }}
                  onClick={handlePlaceOrder}
                  content={place_order[0].text}
                  disabled={
                    cartData?.shipping_addresses?.length === 0 ||
                    !lastFourDigits ||
                    isAProductOutOfStock ||
                    (isPcOfferInCart && !areTermsAccepted) ||
                    cartData?.items?.length === 0
                  }
                />
                {showAutoShip ? (
                  <PrimaryButton
                    onClick={() => navigate('/subscriptions')}
                    content="Manage Subscription"
                    style={{
                      marginTop: '0',
                    }}
                  />
                ) : null}
              </>
            ) : (
              <PrimaryButton
                style={{ margin: '1em 0' }}
                onClick={() => navigate('/login')}
                content={continue_to_checkout[0].text}
              />
            )}
          </Container>
        </>
        {showConfirmation && orderNumber ? (
          <OrderConfirmation
            orderNumber={orderNumber}
            email={cartData.email}
            isEventSite={isEventOrVip}
            shouldRefresh={isEnrollmentComplete || areTermsAccepted}
            handleRefresh={handleRefreshSite}
          />
        ) : null}
        {showOrderError && orderNumber ? (
          <OrderFailed
            orderNumber={orderNumber}
            orderStatus={orderStatus}
            userName={qUser?.displayName}
            errorData={orderError}
          />
        ) : null}
      </Wrapper>
    </>
  )
}

export default CartPage
